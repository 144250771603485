<template>
  <div class="home">
    <v-container fluid class="px-0 pt-0">
      <Hero></Hero>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <News :isCall="true"></News>
        </v-col>
        <v-col cols="12" md="5">
          <Calendar :isCall="true"></Calendar>
        </v-col>
      </v-row>

      <!-- 首頁商城圖片連結--Start -->
      <v-container fluid v-if="$store.state.userInfo.role_id == 20">
        <v-row justify="center">
          <v-col cols="6" md="4">
            <v-img
              :src="require('@/assets/images/mall/vip_bg.png')"
              max-width="90%"
              class="ml-auto"
            >
              <v-container fill-height>
                <v-row justify="center">
                  <v-col cols="6" class="home__mall__link pl-0">
                    <router-link to="/resell">
                      <img
                        :src="require('@/assets/images/mall/vip.png')"
                        min-height="100%"
                      />
                      <h2>重銷商城</h2>
                    </router-link>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-col>
          <v-col cols="6" md="4">
            <v-img
              :src="require('@/assets/images/mall/repurchase_bg.png')"
              max-width="90%"
            >
              <v-container fill-height>
                <v-row justify="center">
                  <v-col cols="6" class="home__mall__link pl-0">
                    <router-link to="/vip_store">
                      <img
                        :src="require('@/assets/images/mall/vip.png')"
                        min-height="100%"
                      />
                      <h2>VIP商城</h2>
                    </router-link>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
      <!-- 首頁商城圖片連結--End -->
    </v-container>
  </div>
</template>

<script>
import Hero from "@/components/home/Hero";
import News from "@/components/home/News";
import Calendar from "@/components/home/Calendar";

export default {
  name: "Home",
  components: {
    Hero,
    News,
    Calendar,
  },
};
</script>
<style lang="scss">
.home {
  overflow: hidden;
}
.home__mall__link {
  text-align: center;
}
.home__mall__link a {
  text-decoration: none;
}
.home__mall__link a h2 {
  color: #ffffff;
  font-weight: 500;
}
</style>
