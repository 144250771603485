import { get, post } from "@/utils/http";

export const newsAPI = {
  get: function(paramObj) {
    return get("news", paramObj);
  },
  getOne: function(paramObj) {
    return get(`news/${paramObj}`);
  },
  post: function(paramObj) {
    return post("news", paramObj);
  },
};

export const calendarAPI = {
  get: function(paramObj) {
    return get("calendar", paramObj);
  },
  getOne: function(paramObj) {
    return get(`calendar/${paramObj}`);
  },
  post: function(paramObj) {
    return post("calendar", paramObj);
  },
};
