<template>
  <div class="hero">
    <v-carousel cycle max-height="50vh">
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.ba1pic"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
import axios from "axios";
export default {
  async created() {
    await axios
      .get("http://web.hilary.com.tw:8085/getapi?type=marquee")
      .then((res) => {
        this.items = res.data.data;
      })
      .catch((e) => {
        this.$message.error(e);
      });
  },
  data() {
    return {
      items: [],
    };
  },
};
</script>
