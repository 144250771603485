<template>
  <div class="news">
    <v-container fluid>
      <v-card outlined>
        <v-row justify="space-between" class="px-3">
          <v-col cols="4">
            <h3>最新消息</h3>
          </v-col>
          <v-col v-if="isCall" cols="3" class="text-right">
            <v-btn text to="news">
              更多消息 >
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <!-- Desktop -->
            <tbody class="hidden-sm-and-down">
              <tr v-for="item in pageList" :key="item.id">
                <td class="pr-0">
                  <v-chip color="blue" class="white--text" label small>
                    {{ item.category_name }}
                  </v-chip>
                </td>
                <td class="px-0">
                  {{ item.create_time }}
                </td>
                <td class="px-0">
                  <router-link :to="`/news/${item.id}`">
                    {{ item.news_title }}
                  </router-link>
                </td>
              </tr>
            </tbody>
            <!-- Mobile -->
            <tbody class="hidden-sm-and-up">
              <tr v-for="item in newsList" :key="item.id">
                <v-row class="px-3">
                  <v-col cols="3">
                    <td>
                      <v-chip color="blue" class="white--text" label small>
                        {{ item.category_name }}
                      </v-chip>
                    </td>
                  </v-col>
                  <v-col>
                    <td>{{ item.create_time }}</td>
                  </v-col>
                </v-row>
                <v-row class="px-3">
                  <v-col>
                    <td>
                      <router-link :to="`/news/${item.id}`">
                        {{ item.news_title }}
                      </router-link>
                    </td>
                  </v-col>
                </v-row>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-col v-if="!isCall" cols="12" class="text-center">
        <v-pagination
          v-model="currentPage"
          :length="Math.ceil(newsList.length / pageSize)"
          :total-visible="6"
        ></v-pagination>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { newsAPI } from "@/api/home/api";

export default {
  props: {
    isCall: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newsList: [],
      currentPage: 1,
      pageSize: 10
    };
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    pageList() {
      return this.newsList.slice(this.indexStart, this.indexEnd);
    }
  },
  created() {
    this.getAllNews();
  },
  methods: {
    getAllNews() {
      newsAPI.get().then(res => {
        this.isCall
          ? (this.newsList = res.data.data.reverse().slice(0, 5))
          : (this.newsList = res.data.data.reverse());
        this.newsList.forEach(element => {
          element.create_time = element.create_time.substr(0, 10);
        });
      });
    }
  }
};
</script>

<style lang="scss">
.news * {
  overflow: hidden;
  text-decoration: none;
}
</style>
