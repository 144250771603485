<template>
  <div class="calendar_content">
    <v-container fluid>
      <v-col cols="12" md="6" class="mx-auto">
        <v-row>
          <v-col
            ><h1>{{ item.calendar_title }}</h1></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <h3>發佈日期： {{ item.create_time }}</h3></v-col
          >
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col class="my-5 ql-editor" v-html="item.calendar_content"></v-col>
        </v-row>
        <v-divider></v-divider>

        <h3 class="text-right my-2">最後更新時間：{{ item.update_time }}</h3>
        <v-row justify="center">
          <v-btn to="/calendar" class="my-5">
            <v-icon left>
              mdi-chevron-up
            </v-icon>
            回列表頁</v-btn
          >
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { calendarAPI } from "@/api/home/api";
export default {
  data() {
    return {
      item: {}
    };
  },
  created() {
    calendarAPI.getOne(this.$route.params.id).then(res => {
      res.data.data.create_time = res.data.data.create_time.slice(0, 10);
      res.data.data.update_time = res.data.data.update_time.slice(0, 10);
      this.item = res.data.data;
    });
  }
};
</script>
