<template>
  <div class="calendar">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <Calendar></Calendar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Calendar from "@/components/home/Calendar";
export default {
  components: {
    Calendar
  }
};
</script>
