<template>
  <div class="news">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <News></News>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import News from "@/components/home/News";
export default {
  components: {
    News
  }
};
</script>
